



































import {
  Ref,
  defineComponent,
  inject,
  onMounted,
  ref,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { debounce } from 'lodash-es';
import Papa from 'papaparse';
import SvgImage from '~/components/General/SvgImage.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import JetSearchBar from './JetSearchBar.vue';

export default defineComponent({
  name: 'SearchBar',
  components: {
    JetSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 1,
    },
  },
  emits: ['set-is-open', 'set-search-results', 'search-terms'],
  setup(props, { emit }) {
    const term = ref('');
    const isListening = ref(false);
    const recognition = ref<SpeechRecognition | null>(null);
    const route = useRoute();
    const placeholder = ref('Encontre o que você precisa agora!');
    const products = ref([]);
    const bestMatch = ref('');
    const bestMatchRef = ref(null);
    const { updateSearchTerms, searchTerms } = inject<{ updateSearchTerms: (term: any) => void; searchTerms: Ref<string> }>('searchTerms');
    const phrases = [
      'Encontre o que você precisa agora!',
      'Descubra ofertas exclusivas!',
      'Seu próximo achado está aqui',
      'Pesquise e economize hoje',
      'Não perca as novidades!',
      'Sua melhor escolha está a um clique',
      'O que você deseja está a um passo',
      'Garanta já o que você procura!',
      'Encontre a inspiração para sua compra',
      'Explore e surpreenda-se com as ofertas',
      'Encontre tudo para seu escritório!',
      'Suprimentos essenciais para o seu dia a dia',
      'Organize seu espaço de trabalho agora',
      'Descubra ofertas em materiais de escritório',
      'Tudo o que você precisa para ser produtivo',
      'Otimize seu escritório com nossos produtos',
      'Suprimentos que fazem a diferença',
      'Deixe seu escritório mais eficiente!',
      'O que falta no seu escritório está aqui',
      'Pesquise e equipe seu escritório',
      'Encontre tudo para seu escritório!',
      'Suprimentos essenciais para o seu dia a dia',
      'Organize seu espaço de trabalho agora',
      'Descubra ofertas em materiais de escritório',
      'Tudo o que você precisa para ser produtivo',
      'Otimize seu escritório com nossos produtos',
      'Suprimentos que fazem a diferença',
      'Deixe seu escritório mais eficiente!',
      'O que falta no seu escritório está aqui',
      'Pesquise e equipe seu escritório',
      'Transforme seu espaço com os melhores produtos',
      'Encontre os itens que fazem a diferença',
      'Tudo para um escritório moderno e eficiente',
      'Faça seu trabalho fluir com nossos produtos',
    ];

    const setRandomPlaceholder = () => {
      const randomIndex = Math.floor(Math.random() * phrases.length);
      placeholder.value = phrases[randomIndex];
    };

    onMounted(() => {
      setRandomPlaceholder();
      setInterval(setRandomPlaceholder, 15_000);

      Papa.parse('/products.csv', {
        header: true,
        download: true,
        dynamicTyping: true,
        complete: function (results) {
          products.value = results.data;
        }
      });
    });

    const findClosestMatch = (term: string) => {
      if (!term) {
        bestMatch.value = '';
        return;
      };

      let closestMatch = '';
      let bestScore = 0;
      const inputWords = term.toLowerCase().split(" ");

      products.value.forEach((product) => {
        if (product.name && term) {
          const words = product.name.toLowerCase().split(" ");
          let score = 0;

          inputWords.forEach((inputWord, inputIndex) => {
            words.forEach((word, wordIndex) => {
              if (word.includes(inputWord)) {
                score += 1;

                if (word === inputWord) {
                  score += 2;
                }

                if (wordIndex === inputIndex) {
                  score += 3;
                }
              }
            });
          });

          if (score > bestScore || (score === bestScore && product.name.length < closestMatch.length)) {
            bestScore = score;
            closestMatch = product.name;
          }
        }
      });

      bestMatch.value = closestMatch;
    };


    const initializeSpeechRecognition = () => {
      if (typeof navigator !== 'undefined') {
        const SpeechRecognition = (window.SpeechRecognition || window.webkitSpeechRecognition) as any;
        recognition.value = new SpeechRecognition();
        recognition.value.lang = 'pt-BR';
        recognition.value.interimResults = false;
        recognition.value.maxAlternatives = 1;

        recognition.value.onstart = () => {
          isListening.value = true;
          showSearch();
        };

        recognition.value.onend = () => {
          isListening.value = false;
        };

        recognition.value.onresult = (event) => {
          const speechResult = event.results[0][0].transcript;
          term.value = correctSpeechResult(speechResult);
          rawHandleSearch(term.value);
        };

        recognition.value.onerror = (event) => {
          console.error(`Speech recognition error detected: ${event.error}`);
          isListening.value = false;
        };
      }
    };

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };

    const completeSearch = () => {
      if (bestMatch.value) {
        term.value = bestMatch.value;
        updateSearchTerms(bestMatch.value);
      };
    };

    const hideSearch = () => {
      bestMatch.value = '';
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        updateSearchTerms('');
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      if (term.value) {
        handleKeydownEnter();
      } else {
        showSearch();
      }
    };

    const toggleVoiceSearch = () => {
      if (isListening.value) {
        recognition.value?.stop();
      } else {
        recognition.value?.start();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const correctSpeechResult = (speechResult: string): string => {
      const corrections = {
        reportér: 'report',
      };
      return speechResult.split(' ').map((word) => corrections[word.toLowerCase()] || word).join(' ');
    };

    const rawHandleSearch = async (searchTerm: string) => {
      findClosestMatch(searchTerm);

      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;
      updateSearchTerms(searchTerm);
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 500);

    const handleKeydownEnter = () => {
      if (term.value.length < props.minTermLen) return;

      hideSearch();
      window.location.href = `/search/${term.value}`;
    };

    watch(searchTerms, () => {
      if (searchTerms.value !== term.value) {
        term.value = searchTerms.value;
      }
    });

    watch(bestMatch, () => {
      const searchElement = document.querySelector('.sf-header__search') as any;
      if (searchElement) {
        const inputWrapper = searchElement.querySelector('.sf-input__wrapper');
        if (window.innerWidth < 1200) {
          bestMatchRef.value.style.width = `${searchElement.offsetWidth - 80}px`;
        } else {
          bestMatchRef.value.style.width = `${searchElement.offsetWidth - 100}px`;
        }
        inputWrapper.style.setProperty('outline', 'none', 'important');

        if (bestMatch.value) {
          inputWrapper.style.paddingTop = '10px';
        } else {
          inputWrapper.style.paddingTop = '0';
        }
      };
    });

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    if (process.client) {
      initializeSpeechRecognition();
    }

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      toggleVoiceSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      isListening,
      placeholder,
      bestMatch,
      completeSearch,
      bestMatchRef,
    };
  },
});
